const metaData = {
  aboutUs: {
    title: "A Mobile & Web App Development Company - Powercode.co.uk",
    description:
      "Looking for a mob or web developer? Powercode.co.uk has dozens of experts and is ready to provide you with a complete team to build and launch your app.",
    keywords: "Powercode.co.uk, web app, mobile, web developer",
    h1: "About Us",
  },
  main: {
    title: "Software Development Company | Powercode London, UK",
    description:
      "Powercode Is a Top-Rated Software Development Company In London, UK. We Offer Bespoke Development Services For Businesses Worldwide. Contact Us Today!",
    keywords:
      "custom software development company in the UK, custom software development company, software development services, software development agency, bespoke software development company, software development company london, software development as a service",
    h1: "Powerсode",
  },
  expertise: {
    title: "A Mobile and Web Application Development Company - Powercode.co.uk",
    description:
      "Powercode.co.uk is among the best software companies providing a wide range of services for your business development. Get a web/mob app & maximize your revenue.",
    keywords: "Powercode.co.uk, mobile, web application development, software companies, business development",
    h1: "Expertise",
    domains: {
      ecommerce: {
        title: "eCommerce Software Development Company | Powercode Services",
        description:
          "Powercode Is A Leading Ecommerce Software Development Company Offering Comprehensive Services, Including Website Design and Development For Your Business",
        keywords:
          "custom ecommerce software development company, b2b ecommerce software development, custom ecommerce software development company, ecommerce software development",
      },
      healthcare: {
        title: "Healthcare Software Development Company | Powercode Services",
        description:
          "Powercode Is a Leading Healthcare Software Development Company Offering Bespoke Services, Including Website and App Design, Software Development for Business",
        keywords:
          "custom healthcare software development, healthcare it services, medical software development, medical software development company, healthcare software development services",
      },
      foodtech: {
        title: "Foodtech Software Development Company | Powercode Services",
        description:
          "Get Custom Foodtech Software Development Services In The Uk With Powercode. Streamline Your Operations And Optimize Your Business. Contact Us Today!",
        keywords:
          "foodtech software development, food delivery software development, foodtech software, delivery software development",
      },
      hospitality: {
        title: "Hospitality Software Development Services | Powercode Company",
        description:
          "Get Custom Hospitality Software Development Services In The Uk With Powercode. Streamline Your Operations And Optimize Your Business. Contact Us Today!",
        keywords:
          "hospitality software development, hospitality software development services, custom hospital software development company, hospital software developer",
      },
      education: {
        title: "Education Software Development Company | Powercode Services",
        description:
          "As a Leading Education Software Development Company, We Offer Customized Solutions. Contact Us For Quality Education Software Development Services.",
        keywords:
          "education software development company, custom ​​education software development services, solutions, products",
      },
      entertainment: {
        title: "Media and Entertainment Software/App Development Company",
        description:
          "We Offer Top-Notch Media and Entertainment Software & App Development Services. Let Us Bring Your Creative Ideas To Life. Contact Us Today for a Free Consultation.",
        keywords:
          "media & entertainment software development company, entertainment app development company, entertainment software, media software development",
      },
      realestate: {
        title: "Real Estate Software Development Company | Powercode Services",
        description:
          "We Specialize In Property Management Software Development for Real Estate Companies. Get Tailored Solutions To Streamline Your Operations. Contact Us Now.",
        keywords:
          "real estate software development company, property management software development, custom real estate software development, real estate software solutions",
      },
      enterprise: {
        title: "Custom Enterprise Software Development Company | Powercode Services",
        description:
          "Looking for Custom Enterprise Software Development Services? Powercode Company Provides Professional Solutions For Your Business Needs. Contact Us Today.",
        keywords:
          "custom enterprise software development company, enterprise software development services, life cycle, solutions, firm",
      },
    },
  },
  services: {
    default: {
      title: "Fast & High Quality Software Engineering - Powercode.co.uk",
      description:
        "Powercode.co.uk is a top IT company providing services as web/mob development, DevOps, UI/UX, product branding, QA, IT consulting & analysis, dedicated team, etc.",
      keywords: "Powercode.co.uk,software engineering, devops, branding, qa, IT consulting, analysis, dedicated",
      h1: "Services",
    },
    mobile: {
      title: "Mobile App Development Company | Services in London, UK",
      description:
        "Powercode Is a Top-Rated Mobile App Development Company In London, UK. We Offer Bespoke Mobile App Development Services For Your Businesses. Contact Us Today!",
      keywords:
        "mobile app development company in uk, mobile app development services, mobile app development company in London, mobile development agency, app development services, mobile app development software, mobile development agency, uk mobile developers, custom web app development services",
    },
    web: {
      title: "Web Development Company | Services in London, UK",
      description:
        "Powercode Is a Top-Rated Web Development Company In London, UK. We Offer Bespoke Mobile App Development Services For Your Businesses. Contact Us Today!",
      keywords:
        "custom web development company in uk, web development company, web development services, website development company in London, london web development service, custom web development services, web development services uk, custom website development company",
    },
    mvp: {
      title: "ᐈ MVP Software Development Services | Powercode UK Company",
      description:
        "Get Your Product To Market Quickly With Powercode's MVP Software Development Services. We Provide Custom Solutions To Validate Your Ideas. Contact Us!",
      keywords:
        "what is mvp in software development, mvp software development example, mvp definition software, mvp software definition, mvp development process, mvp development stages",
    },
    consulting: {
      title: "eCommerce Consultant (UK) - Powercode Consulting Services",
      description:
        "Boost Your Business With Powercode eCommerce Consultant & Consulting Services in London, UK. Get Tailored Solutions To Drive Growth & Sales. Contact Us!",
      keywords:
        "ecommerce consultant, ecommerce consulting services, ecommerce consultant uk, ecommerce consulting agency, ecommerce startup consultant, ecommerce business consultant",
    },
    qa: {
      title: "Software Testing & Quality Assurance (QA) Services | Powercode",
      description:
        "Improve Software Quality with Our Professional Testing and QA Services. Get Reliable Software Testing Services For Better Product or Customer Service",
      keywords:
        "software testing services, quality assurance services, qa consulting services, qa company uk, qa testing company, quality assurance services company",
    },
    design: {
      title: "ᐈ Mobile App Design Services (UK) - UX/UI App Design Company",
      description:
        "Looking For Expert Mobile App Design Services In The UK? Powercode UX/UI Application Design Company Offers Custom Solutions To Elevate Your Brand. Contact Us!",
      keywords:
        "mobile app design, app design company, mobile app design company, app design services, app design development, mobile design and development, app design firm",
    },
  },
  singleService: [
    {
      id: "product_branding",
      data: {
        title: "A Quality UI & UX Design and Branding Studio - Powercode.co.uk",
        description:
          "Powercode.co.uk will become your own web developer providing leading-edge mobile & web design services, eye-catching logo & brand book, and memorable naming ideas.",
        keywords: "Powercode.co.uk, ui, ux design, web developer, mobile, web design",
        h1: "Product Branding",
      },
    },
    {
      id: "it_consulting",
      data: {
        title: "IT Support & Business Consulting Services - Powercode.co.uk",
        description:
          "Powercode.co.uk is among top IT consulting firms that advise businesses how to create a website or mobile app and how to maintain it.",
        keywords: "Powercode.co.uk, IT support, consulting, consulting firms, how to create a website",
        h1: "IT Consulting & Analysis",
      },
    },
    {
      id: "dedicated_team_model",
      data: {
        title: "Dedicated Development Team for Your Project - Powercode.co.uk",
        description:
          "If you're looking for custom software development, Powercode.co.uk will provide you with the best app development team to build your money-making app.",
        keywords: "Powercode.co.uk, custom software development, dedicated development team, ",
        h1: "Dedicated Team Model",
      },
    },
    {
      id: "web_mobile_development",
      data: {
        title: "Web & Mobile Development at Your Service - Powercode.co.uk",
        description:
          "Powercode.co.uk conducts mobile app development and web app development of any complexity worldwide.",
        keywords: "Powercode.co.uk, web, mobile development, mobile app, web app",
        h1: "Web & Mobile Development",
      },
    },
    {
      id: "quality_assurance",
      data: {
        title: "Quality Assurance Service for Your Software - Powercode.co.uk",
        description: "Powercode.co.uk provides quality assurance testing using cutting edge QA testing tools.",
        keywords: "Powercode.co.uk, quality assurance, quality assurance testing, qa, testing tools",
        h1: "Quality Assurance",
      },
    },
    {
      id: "devops",
      data: {
        title: "Experience Benefits of DevOps with Powercode.co.uk",
        description:
          "Powercode.co.uk will help you to apply DevOps to your software. Get a tandem of a skillful DevOps engineer and the latest DevOps tools.",
        keywords: "Powercode.co.uk, devops, devops engineer, devops tools",
        h1: "DevOps",
      },
    },
  ],
  caseStudies: {
    title: "A Portfolio Presenting Mobile & Web Apps - Powercode.co.uk",
    description:
      "Here you can see case studies for some of the web and mobile apps that Powercode.co.uk has successfully developed.",
    keywords: "Powercode.co.uk, portfolio, mobile, web, apps, mobile apps",
    h1: "Case Studies",
  },
  portfolioWebSingle: [
    {
      slug: "techReadyWomen",
      data: {
        title: "App Development for Tech Ladies Education - Powercode.co.uk",
        description:
          "Powercode.co.uk built an app for women to get educated about technologies & successfully run their business.",
        keywords: "Powercode.co.uk, app development, tech ladies, app",
        h1: "TechReadyWomen",
      },
    },
    {
      slug: "ther8",
      data: {
        title: "A Tourist App for a Great Vacation developed by Powercode.co.uk",
        description:
          "In this travel agency application, Powercode.co.uk incorporated the search section so that users can find & choose flights, airline tickets, hotels, and many more.",
        keywords: "Powercode.co.uk, tourist app, travel agency, flights, airline tickets, hotels",
        h1: "TheR8",
      },
    },
    {
      slug: "luxGo",
      data: {
        title: "An App Where You Can Rent a Car - Powercode.co.uk",
        description:
          "Powercode.co.uk developed an informative app for a car rental company. The mix of simplicity & usability makes it a useful tool to get fast car rental services.",
        keywords: "Powercode.co.uk, app, car rental company, usability, car rental services",
        h1: "LuxGo",
      },
    },
    {
      slug: "dealawards",
      data: {
        title: "An App to Compare Internet Providers - Powercode.co.uk",
        description:
          "Powercode.co.uk app developers built a site that helps you choose a beneficial internet service to save money.",
        keywords: "Powercode.co.uk, app, internet providers, app developers, internet service",
        h1: "DealAwards",
      },
    },
    {
      slug: "breville",
      data: {
        title: "App Development to Sell Kitchen Appliances - Powercode.co.uk",
        description:
          "Powercode.co.uk developed an application where users can get appliances online. The app is stuffed with functionality though remains easy for usage.",
        keywords: "Powercode.co.uk, app development, kitchen appliances, application, appliances online",
        h1: "Breville",
      },
    },
    {
      slug: "oneMusic",
      data: {
        title: "An App to Buy Music License for Your Business - Powercode.co.uk",
        description:
          "PowerCode.co.uk built an app where users can buy background music for their businesses. Check this work that bears the highest security measures.",
        keywords: "Powercode.co.uk, app, music license, background music, security",
        h1: "OneMusic",
      },
    },
    {
      slug: "DTSFitness",
      data: {
        title: "A Fitness App Development by Powercode.co.uk",
        description:
          "Powercode.co.uk created a site for online and offline fitness classes with a customized control pannel to update the information on the fitness app.",
        keywords: "Powercode.co.uk, fitness app, fitness classes, control panel",
        h1: "DTS Fitness",
      },
    },
    {
      slug: "openHaus",
      data: {
        title: "An App to Find & Set Appartments for Rent - Powercode.co.uk",
        description:
          "Real estate application with a high level of user involvement developed by Powercode.co.uk. See our usability solutions to let users post information to the site.",
        keywords: "Powercode.co.uk, app, apartments for rent, real estate, application, appliances online",
        h1: "OpenHaus",
      },
    },
    {
      slug: "appliedai",
      data: {
        title: "An App That Unites AI Vendors and Businesses - Powercode.co.uk",
        description:
          "Powercode.co.uk developed an app for the company that provides artificial intelligence services. The app will help you find AI solutions to achieve your goals.",
        keywords: "Powercode.co.uk, app, artificial intelligence, AI",
        h1: "AplliedAI",
      },
    },
  ],
  portfolioMobSingle: [
    {
      slug: "lessons",
      data: {
        title: "A Knowledge Base Software Developed by Powercode.co.uk",
        description:
          "Powercode.co.uk built a mobile app with an incorporated payment gateway where users can share knowledge and notes and ask a certain money reward for them.",
        keywords: "Powercode.co.uk, knowledge base software, mobile app, payment gateway, knowledge",
        h1: "Lessons",
      },
    },
    {
      slug: "skillMatch",
      data: {
        title: "A Career and Recruitment Software Developed by Powercode.co.uk",
        description:
          "SkillMatch is an application for job search and employment that was developed with the usage of a matchmaking system by Powercode.co.uk.",
        keywords: "Powercode.co.uk, career, recruitment software, job search, employment",
        h1: "SkillMatch",
      },
    },
    {
      slug: "YBC",
      data: {
        title: "Mobile App for a Young Business Club - Powercode.co.uk",
        description:
          "Powercode.co.uk built a mob app for a company that unites young Ukrainian entrepreneurs of small businesses & helps thm expand their network.",
        keywords: "Powercode.co.uk, mobile app, mob app, businesses",
        h1: "YBC",
      },
    },
    {
      slug: "suttonsWay",
      data: {
        title: "A Performance Management App for SuttonsWay by Powercode.co.uk.",
        description:
          "Building this mobile app, Powercode.co.uk should mix 2 styles such as formality & casual lightness to make this productivity management tool pleasant to work with.",
        keywords: "Powercode.co.uk, performance management app, mobile app, productivity management",
        h1: "SuttonsWay",
      },
    },
  ],
  ourTeam: {
    title: "How to Create an App of High Quality - Powercode.co.uk",
    description:
      "Looking for a professional app developer? Powercode.co.uk will provide you with more than a single iOS or Android developer. We will give you a dedicated team.",
    keywords: "Powercode.co.uk, how to create an app, app developer, iOS, android developer",
    h1: "Our Team",
  },
  blog: {
    title: "IT Blog by Powercode.co.uk",
    description:
      "An informative blog by Powercode.co.uk that reveals new innovations in apps development and latest news in high technology solutions.",
    keywords: "Powercode.co.uk, blog, new innovations, apps, technology",
    h1: "Our Blog",
  },
  articles: [
    {
      slug: "Aspects_to_consider_when_choosing_a_development_company",
      data: {
        title: "How to Choose Among Software Companies - Powercode.co.uk",
        description:
          "Check this article to get to know the secrets of choosing among software companies to build your web or mobile app.",
        keywords: "Powercode.co.uk, software companies, web, mobile app",
        h1: "6 Aspects to Consider When Choosing a Mob/Web Development Service",
      },
    },
    {
      slug: "How_the_entire_process_goes",
      data: {
        title: "6 Steps of Software Development Life Cycle - Powercode.co.uk",
        description:
          "Powercode.co.uk gives a comprehensible review on how software is developed within its dedicated team. Check this SDLC to learn what your app needs to go through.",
        keywords: "Powercode.co.uk, software development life cycle, software, SDLC",
        h1: "The Entire Process of a Web/Mob App Development by PowerCode",
      },
    },
    {
      slug: "Business_monster_named_GDPR",
      data: {
        title: "What Is GDPR and Its Impact on Brands & Users - Powercode.co.uk",
        description:
          "Read this comprehensible but short guide by Powercode.co.uk to understand the principles of General Data Protection Regulation.",
        keywords: "Powercode.co.uk, what is gdpr, general data protection regulation",
        h1: "Business Monster Named GDPR",
      },
    },
    {
      slug: "How_to_start_building_a_mobile_app",
      data: {
        title: "What to Do to Start Building a Mobile App - Powercode.co.uk",
        description:
          "Still don't know how to create an app? Look what an iOS and Android developer of Powercode.co.uk suggests you.",
        keywords: "Powercode.co.uk, mobile app, how to create an app, Android developer",
        h1: "How to Start Building a Mobile App",
      },
    },
    {
      slug: "A_development_company_or_a_freelance_service",
      data: {
        title: "IT Freelancer vs. Mob/Web Software Companies - Powercode.co.uk",
        description:
          "Powercode.co.uk provides you with thorough comparison of freelance developers and software companies to make a right choice for your mob or web development.",
        keywords: "Powercode.co.uk, freelancer, software companies, web development",
        h1: "A Development Company or a Freelance Service? What to Choose.",
      },
    },
    {
      slug: "Why_you_need_a_mobile_app",
      data: {
        title: "Benefits a Mobile App Can Give You - Powercode.co.uk",
        description:
          "Here you will learn why and how phone apps can become your source of profit. Explore what a single mobile app can give you.",
        keywords: "Powercode.co.uk, mobile app, phone apps",
        h1: "Why You Need a Mobile App",
      },
    },
    {
      slug: "Factors_that_impact_your_app_development_cost",
      data: {
        title: "How Much Does It Cost to Make an App - Powercode.co.uk",
        description:
          "Powercode.co.uk reveals factors that impact on your app cost and what low cost mobile app development may lead to.",
        keywords: "Powercode.co.uk, how much does it cost to make an app, low cost mobile app development, app cost",
        h1: "Factors That Influence Your App Development Cost",
      },
    },
    {
      slug: "Security_risks_that_can_bury_your_business_&_ways_to_survive",
      data: {
        title: "Ways to Survive Security Risks - Powercode.co.uk",
        description:
          "Cyber security of your software is extremely important for you business, and Powercode.co.uk will tell you how to stay cyber protected.",
        keywords: "Powercode.co.uk, cyber security, cyber",
        h1: "Security Risks That Can Bury Your Business & Ways to Survive Them",
      },
    },
    // {
    //   slug: '',
    //   data: {
    //     title: '',
    //     description: '',
    //     keywords: '',
    //     h1: ''
    //   },
    // },
  ],
  locations: {
    title: "A Mobile and Web Application Development Company - Powercode.co.uk",
    description:
      "Powercode.co.uk is a cutting edge software development company with offices in 4 countries worldwide: the USA, Germany, China, Ukraine.",
    keywords: "Powercode.co.uk, mobile, web application, software development company",
    h1: "Locations",
  },
  privacyPolicy: {
    title: "Privacy Policy Complied to GDPR - Powercode.co.uk",
    description:
      "To be aware of your data privacy while browsing the Powercode.co.uk website, read our Pribacy Policy.",
    keywords: "Powercode.co.uk, privacy, policy, privacy policy, data privacy",
    h1: "Privacy Policy",
  },
  subscriptionDetails: {
    title: "Subscription details",
    description: "Subscription details",
    keywords: "Subscription details",
    h1: "Subscription details",
  },
  // contactUs: {
  //   title: '',
  //   description: '',
  //   keywords: '',
  //   h1: ''
  // },
};

export const GLOBAL_SCHEMA_MARKUP = {
  "@context": "https://schema.org",
  "@type": "Organization",
  url: "https://pwr-code.com/",
  logo: "https://pwr-code.com/static/media/logoPrimary.961d1966.svg",
  description: "Software Development Company in the UK",
  address: {
    "@type": "PostalAddress",
    streetAddress: "Verkhniy Val 24",
    addressLocality: "Kyiv",
    addressCountry: "Ukraine",
  },
  sameAs: [
    "https://www.facebook.com/powercode.co.uk",
    "https://www.linkedin.com/company/powercodecouk/",
    "https://twitter.com/powercode_co_uk",
    "https://www.instagram.com/powercode.co.uk/",
    "https://www.behance.net/powercode",
    "https://dribbble.com/Powercode",
  ],
};
export const SCHEMA_MARKUP = {
  contact: {
    "@context": "http://schema.org/",
    "@type": "Organization",
    name: "Powercode",
    url: "https://pwr-code.com/",
    logo: "https://pwr-code.com/static/media/logoPrimary.961d1966.svg",
    telephone: "+442045772044",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Verkhniy Val 24",
      addressLocality: "Kyiv",
      addressCountry: "Ukraine",
    },
    department: [
      {
        "@context": "http://schema.org/",
        "@type": "Organization",
        name: "Powercode",
        url: "https://pwr-code.com/",
        logo: "test",
        telephone: "+442045772044",
        address: {
          "@type": "PostalAddress",
          streetAddress: "Tallis House, 2 Tallis Street",
          addressLocality: "United Kingdom",
          addressCountry: "London",
        },
      },
      {
        "@context": "http://schema.org/",
        "@type": "Organization",
        name: "Powercode",
        url: "https://pwr-code.com/",
        logo: "test",
        telephone: "+442045772044",
        address: {
          "@type": "PostalAddress",
          streetAddress: "Frydagstraße 30 D-44536 Lünen",
          addressLocality: "Dortmund",
          addressCountry: "Germany",
        },
      },
      {
        "@context": "http://schema.org/",
        "@type": "Organization",
        name: "Powercode",
        url: "https://pwr-code.com/",
        logo: "test",
        telephone: "+48571338326",
        address: {
          "@type": "PostalAddress",
          streetAddress: "Mykolaja Kopernika str, 5",
          addressLocality: "Warsaw",
          addressCountry: "Poland",
        },
      },
    ],
  },
};

export default metaData;
